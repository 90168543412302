/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 90번째 로그에서는 Subicura 님을 모시고 스타트업53, Mix-pre 10 II, IT 자격증, 베이스캠프의 이메일 앱 Hey 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "Startup 53"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=t4-Aij_hTV4"
  }, "Startup53: 스타트업 시작은 어떻게 할까? 회사 이름 짓기와 도메인 등록의 모든 것 - YouTube")), "\n"), "\n", React.createElement(_components.h2, null, "Mix-pre 10"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCyWgtWWgPyOimMxm9axU9Tw"
  }, "입코딩 MDD - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/product/mixpre-3-ii/"
  }, "MixPre-3 II - Sound Devices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/product/mixpre-10-ii/"
  }, "MixPre-10 II - Sound Devices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/"
  }, "Home - Sound Devices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/product/888/"
  }, "888 - Sound Devices")), "\n"), "\n", React.createElement(_components.h2, null, "IT 자격증"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/news/157322#csidx118e854cbd968ad9c9adc07cc066b42%C2%A0"
  }, "적어도 ‘억대 연봉’··· 모셔가는 IT 자격증 15선 - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/certification/certified-cloud-practitioner/"
  }, "AWS Certified Cloud Practitioner")), "\n"), "\n", React.createElement(_components.h2, null, "베이스캠프의 이메일 앱 Hey"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dhh"
  }, "DHH (@dhh) / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://basecamp.com/"
  }, "Basecamp: Project Management & Team Communication Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hey.com/"
  }, "HEY - Email at its best, new from Basecamp.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=UCeYTysLyGI"
  }, "Take a tour of HEY - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=50265443"
  }, "알라딘: 리모트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hey.com/pricing/"
  }, "HEY email")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/142697"
  }, "'도도 포인트' 운영사 스포카 9주년...매출 100억, 흑자 전환 - 'Startup's Story Platform’")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forbes.com/sites/boburlingham/2017/05/09/forbes-small-giants-2017-americas-best-small-companies/#37663e0b4c32"
  }, "Forbes Small Giants 2017: America's Best Small Companies")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kakaocorp.com/service/Kakaomail"
  }, "카카오메일 | 카카오")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dhh/status/1275901955995385856"
  }, "(3) DHH on Twitter: \"The HEY stack: - Vanilla Ruby on Rails on the backend, ... / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=2355"
  }, "Hey 의 기술 스택 | GeekNews")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dhh/status/1275905046371155968"
  }, "DHH on Twitter: \"@AdnanEbrahimi Relational databases are good.\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.signalvnoise.com/the-majestic-monolith/"
  }, "The Majestic Monolith - Signal v. Noise")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.rubymotion.com/"
  }, "Write cross-platform native apps in Ruby | RubyMotion")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.postfix.org/"
  }, "The Postfix Home Page")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/willnorris/imageproxy"
  }, "willnorris/imageproxy: A caching, resizing image proxy written in Go")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/dhh/782fb925b57450da28c1e15656779556"
  }, "HEY's Gemfile")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.google.com/answer/1083134?hl=en"
  }, "Status of Google Wave - Google Help")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dhh/status/1272968382329942017"
  }, "(3) DHH on Twitter: \"Wow. I'm literally stunned. Apple just doubled down ... / Twitter")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
